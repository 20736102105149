import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Footer = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const contactInfo = {
    company: 'ARTI LOOK SL',
    address: 'Avinguda del Marqués de Sotelo, 7, 46002 València, Valencia, España',
    email: 'contact@artikive.com',
    phone: '+34960653188'
  };

  const footerLinks = {
    company: ['About Us', 'Careers'],
    resources: ['Blog', 'Help Center'],
    legal: ['Privacy Policy', 'Terms of Service']
  };

  return (
    <footer className="bg-primary text-background">
      <div className="container mx-auto px-4 py-16">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12"
        >
          {/* Contact Information */}
          <div className="space-y-6">
            <h3 className="text-2xl font-display font-bold">Contact Us</h3>
            <div className="space-y-4">
              <div className="flex items-start space-x-3">
                <span className="material-icons text-accent mt-1">business</span>
                <div>
                  <p className="font-semibold">{contactInfo.company}</p>
                  <p className="text-background/80 text-sm">{contactInfo.address}</p>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <span className="material-icons text-accent">email</span>
                <a href={`mailto:${contactInfo.email}`} className="hover:text-accent transition-colors">
                  {contactInfo.email}
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <span className="material-icons text-accent">phone</span>
                <a href={`tel:${contactInfo.phone}`} className="hover:text-accent transition-colors">
                  {contactInfo.phone}
                </a>
              </div>
            </div>
          </div>

          {/* Company Links */}
          <div>
            <h3 className="text-xl font-display font-bold mb-6">Company</h3>
            <ul className="space-y-3">
              {footerLinks.company.map((link, index) => (
                <li key={index}>
                  <a href="#" className="hover:text-accent transition-colors">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Resources Links */}
          <div>
            <h3 className="text-xl font-display font-bold mb-6">Resources</h3>
            <ul className="space-y-3">
              {footerLinks.resources.map((link, index) => (
                <li key={index}>
                  <a href="#" className="hover:text-accent transition-colors">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-xl font-display font-bold mb-6">Legal</h3>
            <ul className="space-y-3">
              {footerLinks.legal.map((link, index) => (
                <li key={index}>
                  <a href="#" className="hover:text-accent transition-colors">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </motion.div>

        {/* Bottom Bar */}
        <div className="border-t border-background/10 mt-12 pt-8 text-center text-background/60">
          <p>&copy; {new Date().getFullYear()} ARTI LOOK SL. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 