import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Introduction = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="max-w-4xl mx-auto text-center"
        >
          <h2 className="text-4xl md:text-5xl font-display font-bold text-primary mb-6">
            A New Era of Art and Culture
          </h2>
          <p className="text-xl text-text mb-12">
            Discover how ArtiKive fuses technology with tradition to preserve cultural heritage in a digital age.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default Introduction; 