import React from 'react';
import { motion } from 'framer-motion';

const Hero = () => {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center">
      {/* Background Image */}
      <div 
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage: 'url("/hero-bg.jpg")',
          backgroundColor: 'rgba(26, 83, 92, 0.85)',
          backgroundBlendMode: 'multiply'
        }}
      />
      
      {/* Content */}
      <div className="relative z-10 text-center px-4 max-w-4xl mx-auto">
        <motion.h1 
          className="font-display text-5xl md:text-6xl font-bold mb-6 text-background"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Preserve Culture, Empower Artistry, Embrace the Future
        </motion.h1>
        
        <motion.p 
          className="font-sans text-xl md:text-2xl mb-8 text-background/90"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          ArtiKive bridges the gap between technology and tradition, transforming digital art into cultural heritage.
        </motion.p>
        
        <motion.div 
          className="flex flex-col sm:flex-row gap-4 justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <button 
            onClick={() => scrollToSection('features')}
            className="px-8 py-3 bg-accent hover:bg-accent-dark rounded-lg font-sans font-semibold text-background shadow-md transition-all duration-300 hover:shadow-lg"
          >
            Explore NFTs
          </button>
          <button 
            onClick={() => scrollToSection('impact')}
            className="px-8 py-3 bg-transparent border-2 border-secondary hover:bg-secondary/10 text-secondary rounded-lg font-sans font-semibold shadow-md transition-all duration-300 hover:shadow-lg"
          >
            Learn More
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero; 