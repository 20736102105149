import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Testimonials = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const testimonials = [
    {
      id: 1,
      quote: "ArtiKive has revolutionized how we view and preserve art.",
      author: "Museum Director",
      icon: "account_balance",
    },
    {
      id: 2,
      quote: "Supporting artists and cultural heritage simultaneously is a game-changer.",
      author: "Art Critic",
      icon: "rate_review",
    }
  ];

  return (
    <section className="py-20 bg-primary relative overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute inset-0 bg-gradient-to-br from-primary-dark to-primary opacity-50" />
      
      <div className="container mx-auto px-4 relative">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="max-w-6xl mx-auto"
        >
          {/* Testimonials */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={testimonial.id}
                initial={{ opacity: 0, x: index === 0 ? -30 : 30 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="bg-white/10 backdrop-blur-sm p-8 rounded-2xl"
              >
                <div className="flex items-start gap-4">
                  <span className="material-icons text-4xl text-accent">
                    format_quote
                  </span>
                  <div>
                    <p className="text-xl text-background mb-4 font-display italic">
                      "{testimonial.quote}"
                    </p>
                    <div className="flex items-center gap-3">
                      <span className="material-icons text-accent">
                        {testimonial.icon}
                      </span>
                      <span className="text-background/90 font-semibold">
                        {testimonial.author}
                      </span>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* CTAs */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="text-center"
          >
            <h3 className="text-3xl md:text-4xl font-display font-bold text-background mb-8">
              Ready to Join the Revolution?
            </h3>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button 
                className="px-8 py-4 bg-accent hover:bg-accent-dark text-background font-bold rounded-xl transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl"
                onClick={() => {}}
              >
                Discover Our NFTs
              </button>
              <button 
                className="px-8 py-4 bg-transparent border-2 border-background text-background font-bold rounded-xl hover:bg-white/10 transition-all duration-300 transform hover:scale-105"
                onClick={() => {}}
              >
                Learn More
              </button>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default Testimonials; 