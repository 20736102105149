import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Features = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const features = [
    {
      id: 1,
      icon: 'diamond',
      title: 'NFT Creation',
      description: 'Create NFTs that are more than just digital assets.',
      benefits: [
        'Culturally enriched',
        'Historically contextual',
        'Globally educational'
      ]
    },
    {
      id: 2,
      icon: 'museum',
      title: 'Cultural Collaborations',
      description: 'Work with local museums and organizations.',
      benefits: [
        'Authenticity guaranteed',
        'Cultural preservation',
        'Educational outreach'
      ]
    },
    {
      id: 3,
      icon: 'eco',
      title: 'Sustainable Model',
      description: 'Support cultural heritage projects with each transaction.',
      benefits: [
        'Sustainability',
        'Cultural impact',
        'Community support'
      ]
    }
  ];

  return (
    <section id="features" className="py-20 bg-gradient-to-b from-white to-background relative overflow-hidden">
      <div className="absolute top-0 left-0 w-64 h-64 bg-primary/5 rounded-full -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-accent/5 rounded-full translate-x-1/2 translate-y-1/2" />
      
      <div className="container mx-auto px-4 relative">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="max-w-6xl mx-auto"
        >
          <div className="text-center mb-20 relative">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={inView ? { opacity: 1, scale: 1 } : {}}
              transition={{ duration: 0.8 }}
            >
              <span className="inline-block text-accent font-semibold mb-4">
                WHAT WE OFFER
              </span>
              <h2 className="text-4xl md:text-5xl font-display font-bold text-primary mb-6 relative">
                Our Features
                <div className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-accent rounded-full" />
              </h2>
              <p className="text-xl text-text max-w-3xl mx-auto">
                Discover how we're revolutionizing the preservation of cultural heritage through innovative technology and sustainable practices.
              </p>
            </motion.div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.id}
                initial={{ opacity: 0, y: 30 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                whileHover={{ 
                  y: -10,
                  boxShadow: '0 10px 30px -10px rgba(0,0,0,0.1)'
                }}
                className="bg-white backdrop-blur-sm bg-opacity-80 p-8 rounded-2xl shadow-lg border border-primary/10 transform transition-all duration-300"
              >
                <div className="text-center mb-6 relative">
                  <div className="absolute inset-0 bg-primary/5 rounded-full transform -translate-y-1/2 scale-150 opacity-50" />
                  <span className="material-icons text-5xl text-primary relative">
                    {feature.icon}
                  </span>
                </div>
                
                <h3 className="text-2xl font-display font-bold text-primary text-center mb-4">
                  {feature.title}
                </h3>
                
                <p className="text-text text-center mb-8">
                  {feature.description}
                </p>
                
                <ul className="space-y-3">
                  {feature.benefits.map((benefit, idx) => (
                    <li key={idx} className="flex items-center text-text group">
                      <span className="material-icons text-accent mr-3 text-sm transform group-hover:scale-110 transition-transform">
                        check_circle
                      </span>
                      <span className="group-hover:text-primary transition-colors">
                        {benefit}
                      </span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Features; 