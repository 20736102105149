import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const CulturalImpact = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const stats = [
    {
      id: 1,
      icon: 'volunteer_activism',
      value: '1.2M+',
      label: 'Projects Funded',
      description: 'Cultural heritage projects supported worldwide'
    },
    {
      id: 2,
      icon: 'diversity_3',
      value: '50K+',
      label: 'Artists Supported',
      description: 'Local artists empowered through our platform'
    },
    {
      id: 3,
      icon: 'public',
      value: '120+',
      label: 'Countries Reached',
      description: 'Global impact on cultural preservation'
    },
    {
      id: 4,
      icon: 'payments',
      value: '$5M+',
      label: 'Funds Distributed',
      description: 'Direct support to preservation initiatives'
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6
      }
    }
  };

  return (
    <section id="impact" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
          className="max-w-4xl mx-auto"
        >
          <h2 className="text-4xl md:text-5xl font-display font-bold text-primary text-center mb-6">
            Impact That Preserves
          </h2>
          <p className="text-xl text-text text-center mb-16">
            Every transaction on ArtiKive supports cultural heritage projects, providing a sustainable model for cultural preservation.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {stats.map((stat) => (
              <motion.div
                key={stat.id}
                variants={itemVariants}
                className="bg-background p-6 rounded-lg shadow-md text-center"
              >
                <span className="material-icons text-4xl text-accent mb-4">
                  {stat.icon}
                </span>
                <h3 className="text-3xl font-display font-bold text-primary mb-2">
                  {stat.value}
                </h3>
                <h4 className="text-lg font-bold text-primary mb-2">
                  {stat.label}
                </h4>
                <p className="text-text text-sm">
                  {stat.description}
                </p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default CulturalImpact; 