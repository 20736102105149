import React from 'react';
import Hero from './components/Hero';
import Introduction from './components/Introduction';
import Features from './components/Features';
import Collaboration from './components/Collaboration';
import CulturalImpact from './components/CulturalImpact';
import Testimonials from './components/Testimonials';
import JoinMission from './components/JoinMission';
import Footer from './components/Footer';

function App() {
  return (
    <div className="min-h-screen bg-background text-text">
      <Hero />
      <Introduction />
      <Features />
      <Collaboration />
      <CulturalImpact />
      <Testimonials />
      <JoinMission />
      <Footer />
    </div>
  );
}

export default App; 