import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const JoinMission = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <section className="py-20 bg-primary">
      <div className="container mx-auto px-4">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="max-w-2xl mx-auto text-center"
        >
          <h2 className="text-4xl md:text-5xl font-display font-bold text-background mb-6">
            Be Part of Our Mission
          </h2>
          <p className="text-xl text-background/90 mb-12">
            Join us in our mission to keep the past alive for future generations.
          </p>

          <motion.form 
            onSubmit={handleSubmit}
            className="space-y-4"
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : {}}
            transition={{ delay: 0.3 }}
          >
            <div className="flex flex-col md:flex-row gap-4">
              <input
                type="text"
                placeholder="Your Name"
                className="flex-1 px-6 py-3 rounded-lg bg-white/10 border border-background/20 text-background placeholder-background/60 focus:outline-none focus:border-background"
              />
              <input
                type="email"
                placeholder="Your Email"
                className="flex-1 px-6 py-3 rounded-lg bg-white/10 border border-background/20 text-background placeholder-background/60 focus:outline-none focus:border-background"
              />
            </div>

            <button
              type="submit"
              className="w-full md:w-auto px-8 py-3 bg-accent hover:bg-accent-dark text-background font-bold rounded-lg transition-colors duration-300 shadow-lg hover:shadow-xl"
            >
              Join Our Community
            </button>
          </motion.form>

          <motion.div 
            className="mt-12 text-background/80 text-sm"
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : {}}
            transition={{ delay: 0.4 }}
          >
            <p>By joining, you'll receive updates about:</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div className="flex items-center justify-center space-x-2">
                <span className="material-icons text-accent">new_releases</span>
                <span>New NFT Releases</span>
              </div>
              <div className="flex items-center justify-center space-x-2">
                <span className="material-icons text-accent">event</span>
                <span>Exclusive Events</span>
              </div>
              <div className="flex items-center justify-center space-x-2">
                <span className="material-icons text-accent">insights</span>
                <span>Impact Reports</span>
              </div>
              <div className="flex items-center justify-center space-x-2">
                <span className="material-icons text-accent">groups</span>
                <span>Community Updates</span>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default JoinMission; 