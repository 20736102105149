import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Collaboration = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const partners = [
    { 
      id: 1, 
      icon: 'museum', 
      name: 'Museum Partners',
      description: 'Leading cultural institutions'
    },
    { 
      id: 2, 
      icon: 'account_balance', 
      name: 'Heritage Organizations',
      description: 'Cultural preservation experts'
    },
    { 
      id: 3, 
      icon: 'palette', 
      name: 'Art Galleries',
      description: 'Contemporary art curators'
    },
    { 
      id: 4, 
      icon: 'school', 
      name: 'Educational Institutions',
      description: 'Academic collaborators'
    },
  ];

  return (
    <section className="py-20 bg-background">
      <div className="container mx-auto px-4">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="max-w-4xl mx-auto"
        >
          <h2 className="text-4xl md:text-5xl font-display font-bold text-primary text-center mb-6">
            Collaborations That Matter
          </h2>
          <p className="text-xl text-text text-center mb-12">
            We collaborate with local museums and cultural organizations to ensure authenticity and enrich every NFT with historical context.
          </p>
          
          {/* Partner Icons Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {partners.map((partner) => (
              <motion.div
                key={partner.id}
                whileHover={{ scale: 1.05 }}
                className="bg-white p-6 rounded-lg shadow-md text-center"
              >
                <span className="material-icons text-5xl text-primary mb-4">
                  {partner.icon}
                </span>
                <h3 className="font-display font-bold text-lg mb-2 text-primary">
                  {partner.name}
                </h3>
                <p className="text-text text-sm">
                  {partner.description}
                </p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Collaboration; 